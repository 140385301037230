.AdModule .aarpe-ad { position: relative; }
.AdModule .aarpe-ad::before { content: 'Advertisement'; display: block;
    position: absolute;
    top: -10px;
    color: #666;
    font-size: 7.5px;
    font-family: Verdana,Arial,Helvetica,sans-serif; text-transform: uppercase;
}

.AdModule .aarpe-ad {
    position: relative;
    min-width: 300px;
}

.advertisement .aarpe-ad-wrapper>.aarpe-ad[data-adsize=leader]
{ min-height: 130px; min-width: 728px; padding-top: 22px; }
.dynamic-leader .aarpe-ad { line-height: 0; }

#app {
    position: relative;
    min-height: 85vh; // 95vh
    z-index: 1;
}

#turn {
    display: none;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.aarpe-everywhere-header {
    z-index: auto !important;
}
.browser-alert {
    background-color: #c23934;
    color: white;
    padding: 10px;
    text-align: center;
    z-index: 9;

    position: absolute;
    /* The code that you need to copy */
    left: 0px;
    right: 0px;
    top: 0px;
}

.suggest-new-cards-modal {
    top: 25vh !important;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .suggest-new-cards-modal {
        top: 28vh !important;
    }

    .shepherd-modal-overlay-container.shepherd-modal-is-visible {
        opacity: 0.8!important;
    }
}


//@media only screen and (orientation:landscape) and (max-width: 1023.98px) {
//    #turn {
//        //display: flex;
//        justify-content: center;
//        align-items: center;
//        min-height: 100vh;
//    }
//    #app {
//        display:none;
//    }
//}
//
///* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
//@media only screen and (orientation:portrait) {
//    #turn {
//        display:none;
//    }
//
//    #app {
//        display:block;
//    }
//}

a {
    cursor: pointer;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}
.button--active {
    background-color: #DBDCDE !important;
}

.button--primary {
    background-color: #0973AE;
}

.share-popover {
    max-width: 300px;
    background-color: #fff;
    padding: 0;

    &.tooltip {
        pointer-events: all;
    }

    .close-popover {
        position: absolute;
        top: -3px;
        right: -3px;
        z-index: 1001;
        cursor: pointer;
        pointer-events: all;
    }
}

.tooltip {
    &.popover {
        .popover-inner {
            padding: 15px 24px !important;
        }
    }

    .tooltip-inner {
        padding: 0 !important;
        background: none !important;
    }

    .tooltip-arrow {
        border-color: #23202E !important;
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -80px;
    margin-left: -80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #EC1300;
    border-color: #EC1300 transparent #EC1300 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.header__search--cont {
    display: block;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    position: relative;

    .header--search__results {
        position: absolute;
        top: 48px;
        width: 100%;
        box-shadow: 0 2px 4px #00000029;

        ul {
            display: block;
            width: 100%;
            max-width: 960px;
            margin: 0 auto;
            padding: 5px 0;
            background: #FFF;
            font-size: 16px;
            line-height: 20px;
            color: #000;
            list-style-type: none;

            li {
                padding: 10px 10px 10px 40px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    width: 18px;
                    height: 18px;
                    margin-top: -9px;
                    border-radius: 50%;
                }

                &.result--search::before {
                    background: #FFF url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%2362727B" stroke-width="2.4" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>') 0 50% no-repeat;
                }

                &.result--video::before {
                    background: #20D3B3 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23FFF" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>') 55% 50%/8px 8px no-repeat;
                }

                &.result--iframe::before {
                    background: #9A9AF7 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="%23FFF" d="M234.8 511.7L196 500.4c-4.2-1.2-6.7-5.7-5.5-9.9L331.3 5.8c1.2-4.2 5.7-6.7 9.9-5.5L380 11.6c4.2 1.2 6.7 5.7 5.5 9.9L244.7 506.2c-1.2 4.3-5.6 6.7-9.9 5.5zm-83.2-121.1l27.2-29c3.1-3.3 2.8-8.5-.5-11.5L72.2 256l106.1-94.1c3.4-3 3.6-8.2.5-11.5l-27.2-29c-3-3.2-8.1-3.4-11.3-.4L2.5 250.2c-3.4 3.2-3.4 8.5 0 11.7L140.3 391c3.2 3 8.2 2.8 11.3-.4zm284.1.4l137.7-129.1c3.4-3.2 3.4-8.5 0-11.7L435.7 121c-3.2-3-8.3-2.9-11.3.4l-27.2 29c-3.1 3.3-2.8 8.5.5 11.5L503.8 256l-106.1 94.1c-3.4 3-3.6 8.2-.5 11.5l27.2 29c3.1 3.2 8.1 3.4 11.3.4z"></path></svg>') 50% 50%/12px 12px no-repeat;
                }

                &.result--article::before {
                    background: #DC7DCD url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23FFF" d="M14,0H3A1,1,0,0,0,2,1V23a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V8H15a1,1,0,0,1-1-1ZM5.5,17h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,5.5,17Zm0-5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,5.5,12Zm5-3h-5A.5.5,0,0,1,5,8.5v-1A.5.5,0,0,1,5.5,7h5a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,10.5,9Z"></path><polygon fill="%23FFF" points="21.414 6 16 6 16 0.586 21.414 6"></polygon></svg>') 50% 50%/10px 10px no-repeat;
                }

                &:hover {
                    cursor: pointer;
                    background: #f4f4f4;
                }

                a {
                    p.result--title {
                        color: #555;
                        font-weight: 500;
                        font-size: 16px;
                    }

                    p.result--description {
                        font-size: 14px;
                        color: #999999;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}


.tiptap-menubar {
    background: #EEEFF0 !important;
}

.tiptap-menubar button {
    background: #EEEFF0 !important;
}

.btn-content {
    color: #62727B !important;
}

.editor__content {
    color: #37474F !important;
}

.tutorial {
    width: 420px !important;
    max-width: 420px !important;
}

.v-step {
    max-width: 420px !important;
    padding: 0 !important;
    color: inherit !important;
}

.shepherd-header {
    background-color: white !important;
    padding-bottom: 0 !important;

    h4 {
        font-size: 2rem;
        line-height: 1.25;
        font-weight: 700;
        color: #37383D;
    }
}

.shepherd-element {
    max-width: 926px!important;

    .shepherd-content {
        max-width: 100%!important;
    }
}

.tutorial-title {
    font-size: 36px;
    text-align: center;
}

.tutorial-subtitle {
    text-align: center;
    font-size: 23px;
}

.tutorial-video-holder {
    padding: 0 100px;
}

.step-holder {
    display: flex;
    min-height: 15px;
    justify-content: center;
    align-items: center;
}
.step {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #A3A3A3;
    margin: 10px 5px;
    cursor: pointer;
}
.step.active {
    background: #EC1500;
    border: 1px solid #EC1500;
}

.close-modal-button {
    position: absolute;
    top: -25px;
    right: -45px;
    cursor: pointer;

    img {
        width: 80%;
    }
}

.tutorial-img {
    text-align: center;
    margin: 20px auto;
}

.next-button {
    background-color: #EC1300!important;
    color: white !important;
    padding: 7px 30px!important;
    border-radius: 50px!important;
    border: 1px solid #EC1300!important;
    font-size: 18px!important;
    min-width: 145px;
}

.prev-button {
    color: #37383D!important;
    background-color: transparent!important;
    border: 1px solid #37383D!important;
    padding: 7px 30px!important;
    border-radius: 50px!important;
}

.end-tour-button {
    color: #A3A3A3 !important;
    background: inherit !important;
    font-weight: 600;
    font-size: 18px;
    border-radius: 20px !important;
    border: 1px solid #A3A3A3 !important;
    text-decoration: none;
}

.end-tour-button.inverse {
    background: #000000 !important;
    color: #fff !important;
    border: 1px solid #000 !important;
}

.end-tour-button:hover {
    text-decoration: none;
}

.step-description {
    font-size: 1rem;
    color: #37383D !important;
}

.shepherd-title {
    flex: auto !important;
    flex-direction: column !important;
    padding: 1em 1em 0 !important;
}

.shepherd-footer {
    padding: 0 2em 1em 1rem!important;
    justify-content: center!important;
}

.shepherd-text {
    line-height: 25px !important;
    padding: 1em !important;
    margin-bottom: 10px;
}

.shepherd-content {
    position: relative;
    max-width: 420px;
    padding: 20px;
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: none !important;
}

.shepherd-arrow::before {
    background-color: white !important;
}

.shepherd-enabled.shepherd-target {
    border-radius: 20px;
}

.shepherd-arrow, .shepherd-arrow::before {
    width: 140px!important;
    height: 140px!important;
}

// mobile tour css
.tutorial-mobile-step {
    max-width: 90% !important;
    background: transparent !important;

    &.last-mobile-step {
        margin: -10px!important;
    }

    .shepherd-arrow {
        display: none !important;
    }

    .shepherd-content {
        background: transparent !important;
        color: #fff !important;
        min-height: 580px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .shepherd-header {
        background: transparent !important;

        h4, p {
            color: #fff !important;
        }

        h4 {
            font-size: 25px;
            padding-top: 30px;
        }

        p {
            font-size: 18px;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    .shepherd-text {
        .tutorial-video-holder {
            padding: 0;

            iframe {
                border-radius: 20px;
            }
        }

        .close-modal-button {
            top: -50px;
            right: -20px;
        }

        .step.active {
            background: #FFF;
            border: 1px solid #fff;
        }
    }

    .shepherd-footer {
        margin-top: 100px !important;
        margin-left: auto;
        margin-right: auto;
        padding: 0 !important;

        .end-tour-button {
            background: #fff !important;
            color: #000 !important;
            border: 1px solid #fff !important;
        }

        .next-button {
            border: 1px solid #fff !important;
        }
    }
}

#card-categories {
    display: none;
}

@media only screen and (min-width: 1024px) {
    //.feed-step .shepherd-arrow:before {
    //    bottom: 115px !important;
    //}

    .grid-cont--xl {
        max-width: 80%;
    }

    #card-categories {
        display: flex;
    }
}

.ctt-header {
    position: absolute;
    top:-51px;
    height: 51px;
    z-index: 99;

    &.has-back {
        top: 0;
    }
}

.ctt-subheader .ctt-switcher {
    margin-left: 100px;
}

.ctt-switcher__item, .ctt-tabs__item {
    cursor: pointer;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 300;
    cursor: pointer;
}

.ctt-card__category--favorites::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 27.15 27.2' style='enable-background:new 0 0 27.15 27.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EC1300;enable-background:new ;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Ccircle class='st0' cx='13.58' cy='13.6' r='13'/%3E%3Cpath class='st1' d='M20.89,10.83h-5.24C15.2,9.62,13.7,5.6,13.75,5.6l-1.96,5.23H6.55l4.16,3.42l-1.39,5.31l4.47-3.39l4.74,3.44 l-1.83-5.55L20.89,10.83z'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.edit-deck-btn {
    padding: 10px 0;
    width: 183px;
    color: #37383D;
    border: 1px solid #37383D;
    border-radius: 25px;
    transition: all 0.2s ease-out;
    text-align: center;

    svg {
        vertical-align: text-top;
    }

    &:hover {
        box-shadow: 0 0 0 1px #37383D;
    }

    &:focus {
        background-color: #37383D;
        color: #fff !important;
    }
}

.discard-btn {
    background: #fff!important;
    padding: 10px 0!important;
    width: 183px;
    color: #37383D!important;
    border: 1px solid #37383D!important;
    border-radius: 25px!important;
    transition: all 0.2s ease-out!important;
    text-align: center!important;

    svg {
        vertical-align: text-top;
    }

    &:hover {
        box-shadow: 0 0 0 1px #37383D!important;
    }

    &:focus {
        background-color: #37383D!important;
        color: #fff !important;
    }
}

.aarp-red-btn {
    padding: 10px 0!important;
    text-align: center;
    width: 183px;
    color: #fff!important;
    background-color: #EC2C00!important;
    border-radius: 30px!important;
    transition: all 0.4s ease-out!important;
    border: 1px solid #EC2C00!important;

    &:hover {
        background-color: #AB2125!important;
    }
}

.save-deck-btn {
    padding: 10px 0;
    text-align: center;
    width: 183px;
    color: #fff;
    background-color: #EC2C00;
    border-radius: 30px;
    transition: all 0.4s ease-out;
    border: 1px solid #EC2C00;

    &:hover {
        background-color: #AB2125;
    }
}

.tour-header {
    text-align: center;

    h4 {
        font-size: 1.7rem;
        font-weight: 700;
    }

    p {
        font-size: 18px;
        font-weight: 400;
    }
}

.tour-content {
    .tour-buttons {
        margin: 20px 0;
        display: flex;
        gap: 10px;
        justify-content: center;
    }

    .tour-note {
        text-align: center;

        span {
            font-weight: 700;
        }
    }
}

.card-help {
    a {
        font-size: 12px;
        color: #2172BF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;

        svg {
            margin-right: 7px;
        }
    }
}

.ctt-subheader {
    position:absolute;
    top:0px;
    .shepherd-target {
        padding: 0 !important;
    }
}

.ctt-banner {
    position: absolute;
    background-color: red;
    z-index: 9999;
    left: 10%;
    width: 80%;
    top: 2px;
}

.ctt-modal {
    width: 90%;
}

.ctt-banner.danger {
    padding: 6px 30px;
    background-color: #c94242;
    font-weight: 500;
    text-align: center;
    color: #FFF;
}
.ctt-banner.danger::before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 17px;
    height: 22px;
    margin-right: 8px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M17.1875 4.8125L4.8125 17.1875"/><path d="M17.1875 17.1875L4.8125 4.8125"/></svg>') 50% 50%/22px 22px no-repeat;
}

.ctt-deck-nav {
    display: none;
}

#logo {
    display: flex;
    vertical-align: middle;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ctt-header__logo {
    display: inline-block;
    margin-right: 10px;
}

.ctt-header__slogan__big {
    display: inline-block;
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: #48415C;
    font-weight:bold;
}

#localize-widget {
  display:none!important;
}

@media screen and (max-width: 1279px) {
    .removed--lg {
        display: none;
    }
    .blocked--lg {
        display: block;
    }

    .flexed--lg {
        display: flex;
    }
}
@media screen and (max-width: 1023px) {
    .categories-btn {
        margin-right: 25px;
    }
    .removed--md {
        display: none;
    }
    .blocked--md {
        display: block;
    }

    .flexed--md {
        display: flex;
    }

    .h2--sm {
        font-size: 23px;
    }

    #logo {
        position: static;
        transform: none;
        max-width: 80%;
    }
}
@media screen and (max-width: 767px) {
    .padded-sm {
        padding-left: 10px;
        padding-top: 5px;
    }
    .removed--sm {
        display: none;
    }
    .blocked--sm {
        display: block;
    }

    .flexed--sm {
        display: flex;
    }

    .onboard-quiz__head {
        padding: 5px 32px;
    }

    .onboard-questionnaire {
        margin-left: 10px;
        margin-right: 10px;
    }

    .onboard-questionnaire__item {
        padding: 5px 5px 45px;
    }
    .onboard-questionnaire__answers {
        margin: 0;
        padding: 5px;
    }

    .onboard-questionnaire__answer {
        min-height: auto;
        padding: 10px 15px;
        font-size: 16px;
    }

    .onboard-questionnaire__heading {
        padding-left: 10px;
        font-size: 30px;
        line-height: 1;
        margin-bottom: 45px;
    }

    .onboard-questionnaire__overline {
        font-size: 14px;
        margin-bottom: 22px;
        margin-top: 45px;
    }

    .h2--sm {
        font-size: 23px;
    }

    .onboard__slide {
        background-size: 50%;
    }
}

@media screen and (max-width: 600px) {
    .removed--xs {
        display: none;
    }
    .blocked--xs {
        display: block;
    }

    .flexed--xs {
        display: flex;
    }

    .ctt-card__added-to-deck {
        font-size: 10px;
    }

    .onboard-quiz__head {
        padding: 5px;
    }

    .h2--sm {
        font-size: 23px;
    }

    .onboard__slide {
        background-size: 50%;
    }

    .edit-deck-btn {
        width: 140px;

        &.categories-btn {
            margin-right: 10px;
        }
    }

    .save-deck-btn {
        width: 150px;
    }
}

@media screen and (max-height: 850px) {
    .tutorial-img {
        height: 190px;
    }
}

@media screen and (max-height: 360px) {
    .onboard-intro__head {
        padding: 6px;
    }

    .onboard-intro {
        align-items: end;
        padding-bottom: 5px;
    }

    .onboard-quiz__head {
        padding: 5px;
    }

    .onboard__slide {
        background-size: 50%;
    }
}

@media screen and (max-width: 1023.98px) {
    .ctt-header__logo {
        position: static;
        transform: none;
    }
}

@media (max-height: 559px), (max-height: 667px) {
    #app {
        min-height: 110vh !important;
    }

    .p--xxl {
        font-size: 16px !important;
    }

    #intro__button {
        margin-top: 10px !important;
    }
}
